import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
	return (
		<AnimationRevealPage>
			<Header />
			<MainFeature1
				subheading={<Subheading>About TSM Inc.</Subheading>}
				heading="We are a modern development agency."
				buttonRounded={false}
				primaryButtonText="See Portfolio"
				description="This agency is unique from other agencies in a way that it is founded by a developer and being run by a developer. Our interview process to hire team members is very lengthy and sophisticated. We strive to provide best digital approach to our clients, in line with their digital requirements."
				imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
			/>
			<MainFeature1
				subheading={<Subheading>Our Vision</Subheading>}
				heading="We aim to disrupt the development space."
				description="We want to use latest available technical tools to help client go to the market with the best available solutions to their development need. We are striving to be the best technical savvy team available in the market"
				buttonRounded={false}
				primaryButtonText="Contact Us"
				imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
				textOnLeft={false}
			/>
			<Features
				subheading={<Subheading>Our Values</Subheading>}
				heading="We follow these."
				description="This agency wants to create a difference in our customers digital journey. We take pride in following main pillars of our offerings:"
				cards={[
					{
						imageSrc: SupportIconImage,
						title: "24/7 Support",
						description:
							"We are providing our customers development and support round the clock. Providing them cost savings, quick turnaround time and a Good night Sleep.",
					},
					{
						imageSrc: ShieldIconImage,
						title: "Strong Teams",
						description:
							"We are founded by developers, run by developers and supported by our technical managers.",
					},
					{
						imageSrc: CustomerLoveIconImage,
						title: "Customer Satisfaction",
						description:
							"We call ourselves digital partners of our customers. We want to take responsiibility of our customers digital journey and let them do what they do best, thrive in  their business. ",
					},
				]}
				linkText=""
			/>
			<ContactUsForm />
			<Footer />
		</AnimationRevealPage>
	);
};
