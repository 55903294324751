import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import drupal from "./images/drupal.png";
import wordpress from "./images/wordpress.png";
import react from "./images/react.png";
import nextjs from "./images/nextjs.png";
import vue from "./images/vue.png";
import gatsby from "./images/gatsby.png";
import aws from "./images/aws.png";
import gcp from "./images/gcp.png";
import puppet from "./images/puppet.png";
import ansible from "./images/ansible.png";
import flask from "./images/flask.png";
import django from "./images/django.png";
import contentful from "./images/contentful.png";
import azure from "./images/azure.png";
import flutter from "./images/flutter.png";

/* Hero */
const Row = tw.div`flex`;
const Wrapper = tw.section`flex w-full mt-12 text-center`;
const Column = tw.div`w-1/3 mr-12 items-center`;
const HeroRow = tw(
  Row
)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */

  const downloadUrl = "/treact-ui.zip";
  React.useEffect(() => {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = downloadUrl;
    document.body.appendChild(iframe);
  }, []);

  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header />
          <HeroRow>
            <Heading>Technologies!</Heading>
            <Description tw="mt-12">
              We are actively using the following logos during our development
              but not limited to these technologies. We are technology
              enthusiasts and will keep adding new logos.
            </Description>
            <Wrapper>
              <Column>
                <img alt="drupal" src={drupal} />
              </Column>
              <Column>
                <img alt="wordpress" src={wordpress} />
              </Column>
              <Column>
                <img alt="contentful" tw="mt-12" src={contentful} />
              </Column>
            </Wrapper>
            <Wrapper>
              <Column>
                <img alt="django" src={django} />
              </Column>
              <Column>
                <img alt="flask" src={flask} />
              </Column>
              <Column>
                <img alt="react" src={react} />
              </Column>
            </Wrapper>
            <Wrapper>
              <Column>
                <img alt="nextjs" src={nextjs} />
              </Column>
              <Column>
                <img alt="vue" src={vue} />
              </Column>
              <Column>
                <img alt="gatsby" src={gatsby} />
              </Column>
            </Wrapper>
            <Wrapper>
              <Column>
                <img alt="ansible" src={ansible} />
              </Column>
              <Column>
                <img alt="puppet" alt="puppet" tw="mt-12" src={puppet} />
              </Column>
              <Column>
                <img alt="aws" src={aws} />
              </Column>
            </Wrapper>
            <Wrapper>
              <Column>
                <img alt="gcp" src={gcp} />
              </Column>
              <Column>
                <img alt="azure" src={azure} />
              </Column>
              <Column>
                <img alt="flutter" src={flutter} />
              </Column>
            </Wrapper>
          </HeroRow>
        </Content2Xl>
      </Container>
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
};
