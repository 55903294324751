import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
	${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
	${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
	${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
	.imageContainer {
		${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
		img {
			${tw`w-8 h-8`}
		}
	}

	.textContainer {
		${tw`mt-6 text-center`}
	}

	.title {
		${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
	}

	.description {
		${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
	}
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
	${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
	/*
	 * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
	 *  1) imageSrc - the image shown at the top of the card
	 *  2) title - the title of the card
	 *  3) description - the description of the card
	 *  If a key for a particular card is not provided, a default value is used
	 */

	const cards = [
		{
			imageSrc: SimpleIconImage,
			title: "Project Management",
			description:
				"Our Project Managers our well versed with modern project management methodology and tools. Apart from being the masters of their craft, our managers bring the human values to the project and easily integrate with diversified fields and deliver project plans, timelines, milstones, documentation, contingency plans and all other required artifacts to run a project smoothly.",
		},
		{
			imageSrc: CustomizeIconImage,
			title: "Development",
			description:
				"We provide certified, experienced devs who have been working on latest fronend frameworks like React, NextJS, Vue, Angular, React Native, Mobile App Development, Flutter. As well as Back End frameworks and CMS's like Drupal, Wordpress, Contentful, Django, Flask. All of our frontend contracts have been technically vetted by our team and have passed thorough reference checks with former colleagues.",
		},
		{
			imageSrc: ShieldIconImage,
			title: "Devops",
			description:
				"Our Devops engineers are meeting the need of ever evolving infrastructure requirements, automating the process of deployment and also making local development as secluded as possible for any new developer onboarding on the project. Our Devops engineers have several years of managing infrastructure on cloud with AWS, Azure, GCP and in house data warehouses. If you require technically resources to secure your infrastructure, automate the deployment process with puppet scripts or any infrastructure as code service look no further.",
		},
	];

	return (
		<Container>
			<ThreeColumnContainer>
				<Heading>
					Our Professional <span tw="text-primary-500">Services</span>
				</Heading>
				{cards.map((card, i) => (
					<Column key={i}>
						<Card>
							<span className="imageContainer">
								<img src={card.imageSrc || defaultCardImage} alt="" />
							</span>
							<span className="textContainer">
								<span className="title">{card.title}</span>
								<p className="description">{card.description}</p>
							</span>
						</Card>
					</Column>
				))}
			</ThreeColumnContainer>
			<DecoratorBlob />
		</Container>
	);
};
