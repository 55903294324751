import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
	SectionHeading,
	Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import * as yup from "yup";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
	tw`md:w-7/12 mt-16 md:mt-0`,
	props.textOnLeft
		? tw`md:mr-12 lg:mr-16 md:order-first`
		: tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
	`background-image: url("${props.imageSrc}");`,
	tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
	SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const ErrorContainer = tw.div`float-left min-w-full`;
const ErrorContent = tw.p`text-red-500 pt-4`;
const SuccessContent = tw.p`text-green-500 pt-4`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`;
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`;
export default ({
	subheading = "Contact Us",
	heading = (
		<>
			Feel free to <span tw="text-primary-500">get in touch</span>
			<wbr /> with us.
		</>
	),
	description = ".",
	submitButtonText = "Contact Us",
	formAction = "#",
	formMethod = "get",
	textOnLeft = true,
}) => {
	let schema = yup.object().shape({
		email: yup.string().email().required(),
	});

	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [accessToken, setAccessToken] = useState("");
	const [success, setSuccess] = useState(false);

	useEffect(async () => {
		if (accessToken !== "") {
			const registerEmail = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify({ email }),
			};
			await fetch(
				process.env.REACT_APP_API_URL + "/newsletter-subscription?_format=json",
				registerEmail
			)
				.then((response) => response.json())
				.then((data) => {
					if (data["data"]["message"] == "It Worked") {
						setEmail("");
						setSuccess(true);
					}
				});
		}
	}, [accessToken]);

	const newsLetterSubscription = (e) => {
		schema.isValid({ email }).then(async (valid) => {
			var subscriptionBody = {
				grant_type: "password",
				client_id: process.env.REACT_APP_CLIENT_ID,
				client_secret: encodeURIComponent(process.env.REACT_APP_CLIENT_SECRET),
				username: process.env.REACT_APP_API_USER,
				password: encodeURIComponent(process.env.REACT_APP_API_USER_PASSWORD),
			};
			var formBody = [];
			for (var property in subscriptionBody) {
				formBody.push(property + "=" + subscriptionBody[property]);
			}
			formBody = formBody.join("&");
			if (!valid) setError("Email is required and needs to be a valid email");
			else {
				setError("");
				const requestOptions = {
					method: "POST",
					headers: { "Content-Type": "application/x-www-form-urlencoded" },
					body: formBody,
				};
				await fetch(
					process.env.REACT_APP_API_URL + "/oauth/token",
					requestOptions
				)
					.then((response) => response.json())
					.then((data) => setAccessToken(data["access_token"]));
			}
		});
	};
	// The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
	return (
		<Container id="contact-us">
			<TwoColumn>
				<ImageColumn>
					<Image imageSrc={EmailIllustrationSrc} />
				</ImageColumn>
				<TextColumn textOnLeft={textOnLeft}>
					<TextContent>
						{subheading && <Subheading>{subheading}</Subheading>}
						<Heading>{heading}</Heading>
						<Description>{description}</Description>
						<Form action={formAction} method={formMethod}>
							<Input
								type="email"
								name="email"
								value={email}
								placeholder="Your Email Address"
								onChange={(e) => setEmail(e.target.value)}
								autocomplete="off"
							/>
							<SubmitButton onClick={newsLetterSubscription} type="button">
								{submitButtonText}
							</SubmitButton>
						</Form>
						<ErrorContainer>
							<ErrorContent>{error !== "" && error}</ErrorContent>
						</ErrorContainer>
						<ErrorContainer>
							<SuccessContent>
								{success &&
									"Thanks for contacting us. We will get back to you asap."}
							</SuccessContent>
						</ErrorContainer>
					</TextContent>
				</TextColumn>
			</TwoColumn>
		</Container>
	);
};
